<template>
	<div>
		<transition name="fade">
			<Message v-if="error" :message="error" type="error" />
		</transition>

		<transition name="fade">
			<Message v-if="redirecting" message="Redirecting..." type="info" />
		</transition>

		<h4>
			Service <a :href="service" target="_blank">{{ service }}</a> would like access to:
		</h4>
		<ul>
			<li v-for="(item, index) of scopes" :key="index">
				{{ item }}
			</li>
		</ul>

		<SubmitButton :disabled="redirecting" @click="accept">
			Accept
		</SubmitButton>

		<SubmitButton :disabled="redirecting" @click="decline">
			Decline
		</SubmitButton>
	</div>
</template>

<script>
import { requireConsent } from "../../configuration/config-public-merged.js";

import store from "@/store";
import api from "@/api";

import SubmitButton	from "@/components/SubmitButton.vue";
import Message		from "@/components/Message.vue";

export default {
	name: "Consent",

	components: {
		SubmitButton,
		Message
	},

	beforeRouteEnter (to, from, next) {
		if (!store.state.scopes || !store.state.service)
			return next({name: "Error"});

		next();
	},

	data () {
		return {
			error: undefined,
			redirecting: false,
			scopes: store.state.scopes,
			service: store.state.service
		};
	},

	beforeMount () {
		if (!requireConsent)
			this.accept();
	},

	methods: {
		decline () {
			window.location.href = this.service;
		},

		async accept () {
			try {
				this.redirecting = true;
				window.location.href = await api.consent();
			} catch (e) {
				console.error(e);

				this.redirecting = false;
				this.error = e.message ? e.message : e;
			}
		}
	}
};
</script>

<style scoped>
h4 {
	text-align: center;
}
</style>
